import * as React from 'react';
import { Container } from 'reactstrap';
import Footer from './Footer';
import Form from './Form';
import NavMenu from './NavMenu';

const Layout = (props: { children: boolean | React.ReactChild | React.ReactFragment | React.ReactNode | null | undefined; }) => {

        React.useEffect(() => {
            let bg = document.querySelector('.main-box-bg');
            let mainBg = document.querySelector('#body_bg');
            window.addEventListener('mousemove', function (e) {
                let x = e.clientX / window.innerWidth;
                let y = e.clientY / window.innerHeight;
                // @ts-ignore
                if (bg && bg.style) {
                    // @ts-ignore
                    bg.style.transform = 'translate(-' + x * 50 + 'px, -' + y * 50 + 'px)';
                }
                // @ts-ignore
                if (mainBg && mainBg.style) {
                    // @ts-ignore
                    mainBg.style.transform = 'translate(-' + x * 100 + 'px, -' + y * 100 + 'px)';
                }
            });
        }, [])

        return (
            <React.Fragment>
                <>
                    
                    <div id="body_overflow">
                        <div id="body_bg"></div>
                        <div id="trackDot" className="dot" style={{ zIndex: 999, display: 'none' }}></div>

                        <NavMenu />

                        <div className="container-body">

                            <Container>
                                {props.children}
                            </Container>
                        </div>
                        <Form/>
                </div>
                    <Footer/>
                </>
            </React.Fragment>
        );
}

export default Layout