﻿import * as React from 'react';
import { Text } from './../Language'
import { Helmet } from 'react-helmet'

const Services = () => {

    React.useEffect(() => {
        document.querySelectorAll(".skill-btn").forEach((item) => {
            // @ts-ignore
            item.onclick = (function (event) {
                document.querySelectorAll(".skill-info").forEach((item) => {
                    // @ts-ignore
                    item.style.display = 'none';
                })
                var name = "#" + event.target.id + "-info";
                // @ts-ignore
                document.querySelector(name).style.display = 'block';
            });
        })

        document.querySelectorAll(".skill-btn-crm").forEach((item) => {
            // @ts-ignore
            item.onclick = (function (event) {
                document.querySelectorAll(".skill-info-crm").forEach((item) => {
                    // @ts-ignore
                    item.style.display = 'none';
                })
                var name = "#" + event.target.id + "-info-crm";
                // @ts-ignore
                document.querySelector(name).style.display = 'block';
            });
        })
    }, [])
    

    return (
        <React.Fragment>
            <Helmet>
                <title>{Text({ tid: 'services_title' })}</title>
                <meta name="description" content={Text({ tid: 'services_description' })} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={Text({ tid: 'services_title' })} />
                <meta property="og:description" content={Text({ tid: 'services_description' })} />
            </Helmet>
            <div className="service-box">
                <div className="service-text" style={{ width: '100%'}}>
                    <h1 className="service-heading"><Text tid='services_h1' /></h1>
                    <h4 className="service-heading"><Text tid='services_h41' /></h4>
                    <h4 className="service-heading"><Text tid='services_h42' /></h4>

                    <div className="service-text-blue">
                        <h2><Text tid='services_service1_h2' /></h2>
                        <p><Text tid='services_service1_p1' /></p>
                        <p><Text tid='services_service1_p2' /></p>
                        <p><Text tid='services_service1_p3' /></p>
                        <p><Text tid='services_service1_p4' /></p>
                    </div>
                    <div className="service-text-white">
                        <h2><Text tid='services_service2_h2' /></h2>
                        <p><Text tid='services_service2_p1' /></p>
                        <p><Text tid='services_service2_p2' /></p>
                        <div className="skill-list">
                            <h3><Text tid='services_service2_skill_h3' /></h3>
                            <div className="skill-buttons">
                                <div className="multi-button">
                                    <button className="skill-btn" id="csharp-skill">C#</button>
                                    <button className="skill-btn" id="js-skill">JS</button>
                                    <button className="skill-btn" id="c-skill">1C</button>
                                    <button className="skill-btn" id="qa-skill">QA</button>
                                    <button className="skill-btn" id="cpp-skill">C++</button>
                                    <button className="skill-btn" id="devops-skill">DevOps</button>
                                    <button className="skill-btn" id="analis-skill"><Text tid='services_service2_skill_btn' /></button>
                                </div>
                            </div>
                            <div style={{ display: 'none' }} className="skill-info" id="js-skill-info">
                                <div>
                                    <h3><Text tid='services_service2_skill_js1_h3' /></h3>
                                    <p>JavaScript, TypeScript, HTML 5, CSS 3</p>
                                </div>
                                <div>
                                    <h3><Text tid='services_service2_skill_js2_h3' /></h3>
                                    <p>Angular 5–9, (NgRx), React, (Redux, MobX, Next.js), VueJS, (Vuex, Nuxt.js), NodeJS(Express), React Native</p>
                                </div>
                            </div>
                            <div className="skill-info" id="csharp-skill-info">
                                <div>
                                    <h3>Web, backend</h3>
                                    <p>ASP.NET MVC, ASP.NET WebAPI, ASP.NET Core</p>
                                </div>
                                <div>
                                    <h3>Desktop</h3>
                                    <p>WinForms, WPF, UWP, Avalonia</p>
                                </div>
                                <div>
                                    <h3><Text tid='services_service2_skill_js3_h3' /></h3>
                                    <p>ADO.NET, Entity Framework, Entity Framework Core, Dapper, NHibernate</p>
                                </div>
                                <div>
                                    <h3>Frontend (fullstack)</h3>
                                    <p>Razor, HTML/CSS, Bootstrap, Javascript, AJAX, jQuery, Angular, React</p>
                                </div>
                                <div>
                                    <h3><Text tid='services_service2_skill_js4_h3' /></h3>
                                    <p>Azure, ServiceFabric, Cosmos DB</p>
                                </div>
                                <div>
                                    <h3><Text tid='services_service2_skill_js5_h3' /></h3>
                                    <p>Dynamics CRM, Sharepoint</p>
                                </div>
                            </div>
                            <div style={{ display: 'none' }} className="skill-info" id="cpp-skill-info">
                                <div>
                                    <h3><Text tid='services_service2_skill_cpp1_h3' /></h3>
                                    <p><Text tid='services_service2_skill_cpp1_p' /></p>
                                </div>
                                <div>
                                    <h3><Text tid='services_service2_skill_cpp2_h3' /></h3>
                                    <p>С++ 98, С++ 11, С++ 14</p>
                                </div>
                                <div>
                                    <h3><Text tid='services_service2_skill_cpp3_h3' /></h3>
                                    <p>OpenCV, Boost, Google Glog, Google Test, QT, Chromium Embedded Framework</p>
                                </div>
                                <div>
                                    <h3><Text tid='services_service2_skill_cpp4_h3' /></h3>
                                    <p>XSLT, MFC, Direct 3D</p>
                                </div>
                            </div>
                            <div style={{ display: 'none' }} className="skill-info" id="c-skill-info">
                                <div>
                                    <h3><Text tid='services_service2_skill_c1_h3' /></h3>
                                    <p>
                                        <Text tid='services_service2_skill_c1_p' />
                                    </p>
                                </div>
                                <div>
                                    <h3><Text tid='services_service2_skill_c2_h3' /></h3>
                                    <p><Text tid='services_service2_skill_c2_p' /></p>
                                </div>
                            </div>
                            <div style={{ display: 'none' }} className="skill-info" id="qa-skill-info">
                                <div>
                                    <h3><Text tid='services_service2_skill_qa1_h3' /></h3>
                                    <p>
                                        <Text tid='services_service2_skill_qa1_p' />
                                    </p>
                                </div>
                                <div>
                                    <h3><Text tid='services_service2_skill_qa2_h3' /></h3>
                                    <p><Text tid='services_service2_skill_qa2_p' /></p>
                                </div>
                                <div>
                                    <h3><Text tid='services_service2_skill_qa3_h3' /></h3>
                                    <p><Text tid='services_service2_skill_qa3_p' /></p>
                                </div>
                            </div>
                            <div style={{ display: 'none' }} className="skill-info" id="devops-skill-info">
                                <div>
                                    <h3><Text tid='services_service2_skill_devops1_h3' /></h3>
                                    <p>Bare metal, AWS, Azure, DO, Proxmox, VMware</p>
                                </div>
                                <div>
                                    <h3><Text tid='services_service2_skill_devops2_h3' /></h3>
                                    <p>Ansible, Terraform, Vagrant</p>
                                </div>
                                <div>
                                    <h3>CI/CD</h3>
                                    <p>Gitlab, Jenkins, Teamcity</p>
                                </div>
                                <div>
                                    <h3><Text tid='services_service2_skill_devops3_h3' /></h3>
                                    <p>Docker, Kubernetes</p>
                                </div>
                                <div>
                                    <h3><Text tid='services_service2_skill_devops4_h3' /></h3>
                                    <p>Kafka, RabbitMQ</p>
                                </div>
                                <div>
                                    <h3><Text tid='services_service2_skill_devops5_h3' /></h3>
                                    <p>VPN, firewalls, Load balancers</p>
                                </div>
                                <div>
                                    <h3><Text tid='services_service2_skill_devops6_h3' /></h3>
                                    <p>Prometheus, ELK</p>
                                </div>
                                <div>
                                    <h3><Text tid='services_service2_skill_devops7_h3' /></h3>
                                    <p><Text tid='services_service2_skill_devops7_p' /></p>
                                </div>
                            </div>
                            <div style={{ display: 'none' }} className="skill-info" id="analis-skill-info">
                                <div>
                                    <h3><Text tid='services_service2_skill_analis1_h3' /></h3>
                                    <p><Text tid='services_service2_skill_analis1_p' /></p>
                                </div>
                                <div>
                                    <h3><Text tid='services_service2_skill_analis2_h3' /></h3>
                                    <p>UML, BPMN, Use Cases, User Stories</p>
                                </div>
                                <div>
                                    <h3><Text tid='services_service2_skill_analis3_h3' /></h3>
                                    <p><Text tid='services_service2_skill_analis3_p' /></p>
                                </div>
                                <div>
                                    <h3><Text tid='services_service2_skill_analis4_h3' /></h3>
                                    <p><Text tid='services_service2_skill_analis4_p' /></p>
                                </div>
                                <div>
                                    <h3><Text tid='services_service2_skill_analis5_h3' /></h3>
                                    <p><Text tid='services_service2_skill_analis5_p' /></p>
                                </div>
                                <div>
                                    <h3><Text tid='services_service2_skill_analis6_h3' /></h3>
                                    <p>API, Swagger, JSON, XSD, XML, REST, SOAP</p>
                                </div>
                                <div>
                                    <h3><Text tid='services_service2_skill_analis7_h3' /></h3>
                                    <p><Text tid='services_service2_skill_analis7_p' /></p>
                                </div>
                                <div>
                                    <h3><Text tid='services_service2_skill_analis8_h3' /></h3>
                                    <p>Axure, Confluence, Enterprise Architect, Figma, Microsoft Visio, Visual Paradigm, Diagrams</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="service-text-blue">
                        <h2><Text tid='services_service3_h2' /></h2>
                        <p><Text tid='services_service3_p1' /></p>
                        <p><Text tid='services_service3_p2' /></p>
                        <p><Text tid='services_service3_p3' /></p>
                        <p><Text tid='services_service3_p4' /></p>
                        <p><Text tid='services_service3_p5' /></p>
                    </div>
                    <div className="service-text-white">
                        <h2><Text tid='services_service4_h2' /></h2>
                        <p><Text tid='services_service4_p1' /></p>
                        <p><Text tid='services_service4_p2' /></p>
                        <p><Text tid='services_service4_p3' /></p>
                        <p><Text tid='services_service4_p4' /></p>
                        <div className="skill-list">
                            <h3><Text tid='services_service4_skill_h3' /></h3>
                            <div className="skill-buttons">
                                <div className="multi-button">
                                    <button className="skill-btn-crm" id="crm-skill">CRM</button>
                                    <button className="skill-btn-crm" id="infr-skill"><Text tid='services_service4_skill_infr' /></button>
                                    <button className="skill-btn-crm" id="subd-skill"><Text tid='services_service4_skill_subd' /></button>
                                    <button className="skill-btn-crm" id="net-skill"><Text tid='services_service4_skill_net' /></button>
                                    <button className="skill-btn-crm" id="query-skill"><Text tid='services_service4_skill_query' /></button>
                                </div>
                            </div>
                            <div style={{ display: 'none' }} className="skill-info-crm" id="crm-skill-info-crm">
                                <h3>BPMSoft, Creatio(Terrasoft), amoCRM, ZohoCRM, Bitrix24, elmaCRM</h3>
                            </div>
                            <div className="skill-info-crm" id="infr-skill-info-crm">
                                <h3>Amazon Web Services, Microsoft Azure, Proxmox, VMware, Yandex Cloud, Bare metal, AWS, Azure, DO</h3>
                            </div>
                            <div style={{ display: 'none' }} className="skill-info-crm" id="subd-skill-info-crm">
                                <h3>PostgreSQL, MySQL, MSSql, Redis, Oracle</h3>
                            </div>
                            <div style={{ display: 'none' }} className="skill-info-crm" id="net-skill-info-crm">
                                <h3>VPN, Firewalls, Load balancers</h3>
                            </div>
                            <div style={{ display: 'none' }} className="skill-info-crm" id="query-skill-info-crm">
                                <h3>Kafka, RabbitMQ</h3>
                            </div>
                        </div>
                    </div>
                    <div className="service-text-blue">
                        <h2><Text tid='services_service5_h2' /></h2>
                        <p><Text tid='services_service5_p1' /></p>
                        <p><Text tid='services_service5_p2' /></p>
                        <p>
                            <ul>
                                <li><Text tid='services_service5_li1' /></li>
                                <li><Text tid='services_service5_li2' /></li>
                                <li><Text tid='services_service5_li3' /></li>
                                <li><Text tid='services_service5_li4' /></li>
                                <li><Text tid='services_service5_li5' /></li>
                                <li><Text tid='services_service5_li6' /></li>
                            </ul>
                        </p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Services