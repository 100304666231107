﻿import * as React from 'react';
import { Helmet } from 'react-helmet'
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Slider } from '../Slider';
import { LanguageContext, Text } from './../Language'

const Application = () => {

    
    const [app, setApp] = useState<any>({});
    const [slider, setSlider] = useState([{ id: 1, path: '' }]);
    const [loaded, setLoaded] = useState(false);
    const [date, setDate] = useState('');
    const { userLanguage } = useContext(LanguageContext);

    useEffect(() => {
        fetch('api' + window.location.pathname, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => {
                setApp(data);
                setSlider(data.images);
                if (data.lastUpdate != '') {
                    let dates = data.lastUpdate.split('-');
                    setDate(dates[2] + "." + dates[1] + "." + dates[0]);
                }
                setLoaded(true);
            })
            .catch(err => {
                console.log(err)
            });


        const isViewed = localStorage.getItem("view" + window.location.pathname);
        if (!isViewed) {
            fetch('api' + window.location.pathname, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(() => {
                    localStorage.setItem("view" + window.location.pathname, "true");
                })
                .catch(err => console.log(err));
        }
        
    }, [])

    const handleEmail = (subject: any) => {
        // @ts-ignore
        document.querySelector('#subject').value = subject + ' ' + app.name;
    }


    return (
        <React.Fragment>
            <div className="button-back-to-shop">
                <Link to="/shop">← <Text tid='applacation_back' /></Link>
            </div>
            {app && app.name &&

                <div className='app-page'>
                    <Helmet>
                        <title>{userLanguage == "ru" ? app.name : app.nameEn}</title>
                        <meta name="description" content={userLanguage == "ru" ? app.shortDescription : app.shortDescriptionEn} />
                        <meta property="og:type" content="website" />
                        <meta property="og:title" content={userLanguage == "ru" ? app.name : app.nameEn} />
                        <meta property="og:description" content={userLanguage == "ru" ? app.shortDescription : app.shortDescriptionEn} />
                    </Helmet>
                <div className='app-page-description'>
                    
                    {app.images.length != 0 ? <Slider slides={app.images} /> : <img src='/img/appInDevelop.png' className='img-deploy' />}
                    <div className='app-page-text'>
                        <h2>{userLanguage == "ru" ? app.name : app.nameEn}</h2>
                        <p>{userLanguage == "ru" ? app.shortDescription : app.shortDescriptionEn}</p>
                        <div>
                            <p><Text tid='applacation_developer' />: {userLanguage == "ru" ? app.developer.name : app.developer.nameEn}</p>
                            <img src={app.developer.logo} />
                        </div>
                        <div>
                            <p><Text tid='applacation_installs' />: {app.countOfInstall}</p>
                            <p><Text tid='applacation_views' />: {app.countOfViews}</p>
                        </div>
                        <p><Text tid='applacation_compatible' />: {app.platforms.length != 0 && app.platforms.map((item: any) => <span>{userLanguage == "ru" ? item.name : item.nameEn}; </span>)}</p>
                        {app.certifiers.length != 0 && <p><Text tid='applacation_certifier' />:  {app.certifiers.map((item: any) => <span>{userLanguage == "ru" ? item.name : item.nameEn}; </span>)}</p>}
                        {date != '' && <p><Text tid='applacation_last_update' />: {date}</p>}
                        <p><Text tid='applacation_price' />: {app.isFree ? <Text tid='applacation_free' />
                            : <Text tid='applacation_not_free' />}</p>
                        <a className="app-page-button blue" data-toggle="modal"
                            data-target="#exampleModal" href="#exampleModal" onClick={() => handleEmail('Купить')}><Text tid='applacation_buy' /></a>
                        <a className="app-page-button white" data-toggle="modal"
                            data-target="#exampleModal" href="#exampleModal" onClick={() => handleEmail('Запросить демонстрацию')}><Text tid='applacation_demo' /></a>
                    </div>
                </div>
                <div className='main-text'>
                    <h3><Text tid='applacation_description' /></h3>
                    <div dangerouslySetInnerHTML={{ __html: userLanguage == "ru" ? app.description : app.descriptionEn}}></div>
                    {app.termsOfSupport != '' && <>
                        <h3><Text tid='applacation_support' /></h3>
                        <div dangerouslySetInnerHTML={{ __html: userLanguage == "ru" ? app.termsOfSupport : app.termsOfSupportEn}}></div>
                    </>}
                    {app.technicalRequirements != '' && <>
                        <h3><Text tid='applacation_tech' /></h3>
                        <div dangerouslySetInnerHTML={{ __html: userLanguage == "ru" ? app.technicalRequirements : app.technicalRequirementsEn}}></div>
                    </>}
                </div>
            </div>}
            {loaded ? '' :
                <div className='app-not-found'>
                    <img src='../../img/loading.svg' />
                </div>}
                
        </React.Fragment>
    );
}

export default Application