﻿import * as React from 'react';
import { Text } from './../Language'
import { Helmet } from 'react-helmet'

const Vacancy = (props: any) => {

    return (
        <React.Fragment>
            <Helmet>
                <title>{Text({ tid: 'vacancy_title' })}</title>
                <meta name="description" content={Text({ tid: 'vacancy_description' })} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={Text({ tid: 'vacancy_title' })} />
                <meta property="og:description" content={Text({ tid: 'vacancy_description' })} />
            </Helmet>
            <div className='vacancy'>
                <h2><Text tid='vacancy_h2' /></h2>
                <h4><Text tid='vacancy_h4' /></h4>
                <p className="text"><Text tid='vacancy_p' /></p>
                <h3><Text tid='vacancy_h3' /></h3>
                <div className="vacancy-advantages">
                    <div className="vacancy-advantages-item">
                        <div className="vacancy-advantages-title">
                            <h4><Text tid='vacancy_advantages1_h4' /></h4>
                            <img src="/img/project.svg" />
                        </div>
                        <p><Text tid='vacancy_advantages1_p' /></p>
                    </div>
                    <div className="vacancy-advantages-item">
                        <div className="vacancy-advantages-title">
                            <h4><Text tid='vacancy_advantages2_h4' /></h4>
                            <img src="/img/analytics.svg" />
                        </div>
                        <p><Text tid='vacancy_advantages2_p' /></p>
                    </div>
                    <div className="vacancy-advantages-item">
                        <div className="vacancy-advantages-title">
                            <h4><Text tid='vacancy_advantages3_h4' /></h4>
                            <img src="/img/collaborate.svg" />
                        </div>
                        <p><Text tid='vacancy_advantages3_p' /></p>
                    </div>
                    <div className="vacancy-advantages-item">
                        <div className="vacancy-advantages-title">
                            <h4><Text tid='vacancy_advantages4_h4' /></h4>
                            <img src="/img/money.svg" />
                        </div>
                        <p><Text tid='vacancy_advantages4_p' /></p>
                    </div>
                </div>
                <h3><Text tid='vacancy_we_need_h3' /></h3>
                <ul>
                    <li><Text tid='vacancy_we_need_li1' /></li>
                    <li><Text tid='vacancy_we_need_li2' /></li>
                    <li><Text tid='vacancy_we_need_li3' /></li>
                </ul>

                <p className="vacancy-request"><Text tid='vacancy_we_need_p1' />
                    <a href="mailto:request@sprint-m.tech"> request@sprint-m.tech </a><Text tid='vacancy_we_need_p2' /></p>
            </div>
        </React.Fragment>
    );
}

export default Vacancy