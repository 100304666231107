﻿import * as React from 'react';
import { Link } from 'react-router-dom';
import { Text } from './Language'

export default class Footer extends React.PureComponent<{}, { children?: React.ReactNode }> {
    public render() {

        const year = new Date().getFullYear().toString();

        return (
            <React.Fragment>
                <div id="footer">
                    <div className="footer wrapper">
                        <div className="footer-container flex-box">
                            <div className="flex-item" style={{ maxWidth: 'none' }}>
                                <div className="contant-flex-item" style={{ display: 'flex', maxWidth: 'none', justifyContent: 'space-between' }}>
                                    <p style={{ display: 'flex' }}><a href="tel:+79002848612"><i className="fas fa-phone"
                                        style={{ color: '#9b9b9b', fontSize: '22px', width: '22px' }}><svg className="svg-inline--fa fa-phone fa-w-16" style={{ color: '#9b9b9b', fontSize: '22px', width: '22px' }} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"></path></svg></i>
                                        +79002848612</a></p>
                                    <p style={{ display: 'flex', alignItems: 'center' }}><i className="fas fa-map-marker-alt"
                                        style={{ color: '#9b9b9b', fontSize: '22px', width: '22px' }}><svg className="svg-inline--fa fa-map-marker-alt fa-w-12" style={{ color: '#9b9b9b', fontSize: '22px', width: '22px' }} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="map-marker-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg=""><path fill="currentColor" d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path></svg></i>
                                        <span><Text tid='footer_address' /></span></p>
                                    <p style={{ display: 'flex' }}><a href="mailto:request@sprint-m.tech"><i className="far fa-envelope"
                                        style={{ color: '#9b9b9b', fontSize: '22px', width: '22px' }}><svg className="svg-inline--fa fa-envelope fa-w-16" style={{ color: '#9b9b9b', fontSize: '22px', width: '22px' }} aria-hidden="true" focusable="false" data-prefix="far" data-icon="envelope" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z"></path></svg></i>
                                        request@sprint-m.tech</a></p>
                                </div>
                            </div>
                        </div>
                        <div className="footer-container__copyright flex-box">
                            <div className="board"></div>
                            <div className="container_row flex-box">

                                <div className="copyright_text"><Link to="/privacy" style={{ color: 'white' }} >© {year}
                                    <Text tid='footer_privacy' />
                                </Link></div>
                                <div className="follow_links">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
