﻿import * as React from 'react';
import Slider from "react-slick";
import { Text } from './../Language'
import { Helmet } from 'react-helmet'

const Main = () => {

    function reveal() {
        var reveals = document.querySelectorAll(".reveal");

        for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = 150;

            if (elementTop < windowHeight - elementVisible) {
                reveals[i].classList.add("active");
            } else {
                reveals[i].classList.remove("active");
            }
        }
    }

    

    React.useEffect(() => {
        window.addEventListener("scroll", reveal);

        var navLinks = document.querySelectorAll(".carousel .nav-link");
        let slides = document.querySelectorAll(".carousel .slides img");
        let overlays = document.querySelectorAll(".carousel .bar");
        let maxZIndex = navLinks.length;
        let easeInOutQuart = "cubic-bezier(0.77, 0, 0.175, 1)";
        var currentSlideIndex = 0;

        slides[0].classList.add("active");
        navLinks[0].classList.add("active");
        navLinks.forEach((navLink, activeIndex) => {
            // @ts-ignore
            overlays[activeIndex].style.zIndex = `${navLinks.length -
                activeIndex}`;
            navLink.addEventListener("click", () => {
                currentSlideIndex = activeIndex;
                // nav-link
                navLinks.forEach(navLink => navLink.classList.remove("active"));
                navLink.classList.add("active");
                // slide
                let currentSlide: any = document.querySelector(".carousel .slides img.active");

                let slideFadeOut = currentSlide.animate([
                    { transform: "translateX(0)", opacity: 1 },
                    { transform: "translateX(5%)", opacity: 0 }
                ], {
                    duration: 600,
                    easing: "ease-in",
                    fill: "forwards"
                });
                slideFadeOut.onfinish = () => {
                    slides.forEach(slide => slide.classList.remove("active"));
                    let activeSlide = slides[activeIndex];
                    activeSlide.classList.add("active");
                    activeSlide.animate([
                        {
                            transform: "translateX(-5%)",
                            opacity: 0
                        },
                        {
                            transform: "translateX(0)",
                            opacity: 1
                        }
                    ], { duration: 600, easing: "ease-out", fill: "forwards" });
                };
                // overlay
                maxZIndex += 1;
                let activeOverlay = overlays[activeIndex];
                // @ts-ignore
                activeOverlay.style.zIndex = `${maxZIndex}`;
                activeOverlay.animate([{ transform: "scaleX(0)" }, { transform: "scaleX(1)" }], { duration: 1200, fill: "forwards", easing: easeInOutQuart });
                makeTimer();
            });
        });

        makeTimer();
        let timer: any = 0;
        function makeTimer() {
            clearInterval(timer)
            timer = setInterval(function () {
                currentSlideIndex++;
                if (currentSlideIndex > 3)
                    currentSlideIndex = 0;
                let elem = navLinks[currentSlideIndex];
                let event = new Event("click");
                elem.dispatchEvent(event);
            }, 3000);
        }
    }, [])

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{Text({ tid: 'main_title' })}</title>
                <meta name="description" content={Text({ tid: 'main_description' })} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={Text({ tid: 'main_title' })} />
                <meta property="og:description" content={Text({ tid: 'main_description' })} />
            </Helmet>
            <div className="main-box">
                <div className="main-box-bg">
                </div>
                <h1 className="main-box-text"><Text tid='main_box_text1' /></h1>
                <p className="main-box-text" dangerouslySetInnerHTML={{ __html: Text({ tid: 'main_box_text2' }) }} ></p>
                <div className="we-do-box">
                    <div>
                        <svg width="80" height="80" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M28 17V22H4V6H14V4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V22C2 22.5304 2.21071 23.0391 2.58579 23.4142C2.96086 23.7893 3.46957 24 4 24H12V28H8V30H24V28H20V24H28C28.5304 24 29.0391 23.7893 29.4142 23.4142C29.7893 23.0391 30 22.5304 30 22V17H28ZM18 28H14V24H18V28Z"
                                fill="#1049A9" />
                            <path
                                d="M30 10V8H27.8989C27.77 7.37406 27.5214 6.77888 27.167 6.2471L28.657 4.7571L27.243 3.3431L25.753 4.8331C25.2212 4.47861 24.626 4.23006 24 4.1011V2H22V4.1011C21.3741 4.23004 20.7789 4.47855 20.2471 4.833L18.7571 3.343L17.3431 4.757L18.8331 6.247C18.4786 6.77881 18.2301 7.37402 18.1011 8H16V10H18.1011C18.23 10.6259 18.4786 11.2211 18.833 11.7529L17.343 13.2429L18.757 14.6569L20.247 13.1669C20.7788 13.5214 21.374 13.7699 22 13.8989V16H24V13.8989C24.6259 13.77 25.2211 13.5214 25.7529 13.167L27.2429 14.657L28.6569 13.243L27.1669 11.753C27.5214 11.2212 27.7699 10.626 27.8989 10H30ZM23 12C22.4067 12 21.8266 11.8241 21.3333 11.4944C20.8399 11.1648 20.4554 10.6962 20.2284 10.1481C20.0013 9.59987 19.9419 8.99667 20.0576 8.41473C20.1734 7.83279 20.4591 7.29824 20.8787 6.87868C21.2982 6.45912 21.8328 6.1734 22.4147 6.05764C22.9967 5.94189 23.5999 6.0013 24.1481 6.22836C24.6962 6.45542 25.1648 6.83994 25.4944 7.33329C25.8241 7.82664 26 8.40666 26 9C25.9991 9.79538 25.6828 10.5579 25.1204 11.1204C24.5579 11.6828 23.7954 11.9991 23 12Z"
                                fill="#1049A9" />
                        </svg>
                        <p><Text tid='main_we_do_text1' /></p>
                    </div>
                    <div>
                        <svg width="80" height="80" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M30 24V22H27.8989C27.77 21.3741 27.5214 20.7789 27.167 20.2471L28.657 18.7571L27.243 17.3431L25.753 18.8331C25.2212 18.4786 24.626 18.2301 24 18.1011V16H22V18.1011C21.3741 18.23 20.7789 18.4786 20.2471 18.833L18.7571 17.343L17.3431 18.757L18.8331 20.247C18.4786 20.7788 18.2301 21.374 18.1011 22H16V24H18.1011C18.23 24.6259 18.4786 25.2211 18.833 25.7529L17.343 27.2429L18.757 28.6569L20.247 27.1669C20.7788 27.5214 21.374 27.7699 22 27.8989V30H24V27.8989C24.6259 27.77 25.2211 27.5214 25.7529 27.167L27.2429 28.657L28.6569 27.243L27.1669 25.753C27.5214 25.2212 27.7699 24.626 27.8989 24H30ZM23 26C22.4067 26 21.8266 25.8241 21.3333 25.4944C20.8399 25.1648 20.4554 24.6962 20.2284 24.1481C20.0013 23.5999 19.9419 22.9967 20.0576 22.4147C20.1734 21.8328 20.4591 21.2982 20.8787 20.8787C21.2982 20.4591 21.8328 20.1734 22.4147 20.0576C22.9967 19.9419 23.5999 20.0013 24.1481 20.2284C24.6962 20.4554 25.1648 20.8399 25.4944 21.3333C25.8241 21.8266 26 22.4067 26 23C25.9991 23.7954 25.6828 24.5579 25.1204 25.1204C24.5579 25.6828 23.7954 25.9991 23 26Z"
                                fill="#1049A9" />
                            <path
                                d="M28 4H4C3.46973 4.00053 2.96133 4.21141 2.58637 4.58637C2.21141 4.96133 2.00053 5.46973 2 6V26C2.00061 26.5302 2.21152 27.0386 2.58646 27.4135C2.9614 27.7885 3.46975 27.9994 4 28H14V26H4V12H28V15H30V6C29.9994 5.46975 29.7885 4.9614 29.4135 4.58646C29.0386 4.21152 28.5302 4.00061 28 4ZM28 10H4V6H28V10Z"
                                fill="#1049A9" />
                            <path
                                d="M20 9C20.5523 9 21 8.55228 21 8C21 7.44772 20.5523 7 20 7C19.4477 7 19 7.44772 19 8C19 8.55228 19.4477 9 20 9Z"
                                fill="#1049A9" />
                            <path
                                d="M23 9C23.5523 9 24 8.55228 24 8C24 7.44772 23.5523 7 23 7C22.4477 7 22 7.44772 22 8C22 8.55228 22.4477 9 23 9Z"
                                fill="#1049A9" />
                            <path
                                d="M26 9C26.5523 9 27 8.55228 27 8C27 7.44772 26.5523 7 26 7C25.4477 7 25 7.44772 25 8C25 8.55228 25.4477 9 26 9Z"
                                fill="#1049A9" />
                        </svg>
                        <p><Text tid='main_we_do_text2' /></p>
                    </div>
                </div>
            </div>
            <div id="container_contant">
                <Slider {...settings}>
                    <div className="slider-element" style={{ display: 'flex' }}>
                        <div className="slider-element-info">
                            <div>
                                <h2><Text tid='main_slider_1_h2' /></h2>
                                <p style={{ marginBottom: '10px' }}><Text tid='main_slider_1_h51' /></p><br />

                                <p style={{ marginBottom: '10px' }}><Text tid='main_slider_1_h52' /></p><br />
                            </div>
                        </div>
                        <div className="slider-element-img" style={{
                            background: 'url(https://raw.githubusercontent.com/doomer2000/speed5team-site/main/docs/assets/soprov_slider.jpeg)'
                            , backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'
                        }}></div>
                    </div>
                    <div className="slider-element" style={{ display: 'flex' }}>
                        <div className="slider-element-info">
                            <div>
                                <h2><Text tid='main_slider_2_h2' /></h2>
                                <p style={{ marginBottom: '10px' }}><Text tid='main_slider_2_h51' /></p> <br />
                                <p style={{ marginBottom: '10px' }}><Text tid='main_slider_2_h52' /></p><br />
                            </div>
                        </div>
                        <div className="slider-element-img" style={{
                            background: 'url(https://raw.githubusercontent.com/doomer2000/speed5team-site/main/docs/assets/database_slider.webp)'
                            , backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'
                        }}></div>
                    </div>
                    <div className="slider-element" style={{ display: 'flex' }}>
                        <div className="slider-element-info">
                            <div>
                                <h2><Text tid='main_slider_3_h2' /></h2>
                                <p style={{ marginBottom: '10px' }}><Text tid='main_slider_3_h51' /></p><br />
                                <p style={{ marginBottom: '10px' }}><Text tid='main_slider_3_h52' /></p> <br />
                            </div>
                        </div>
                        <div className="slider-element-img" style={{
                            background: 'url(https://raw.githubusercontent.com/doomer2000/speed5team-site/main/docs/assets/individual_slider.jpg)'
                            , backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'
                        }}></div>
                    </div>
                    <div className="slider-element" style={{ display: 'flex' }}>
                        <div className="slider-element-info">
                            <div>
                                <h2><Text tid='main_slider_4_h2' /></h2>
                                <p style={{ marginBottom: '5px', marginTop: '5px' }}><Text tid='main_slider_4_h51' />
                                </p> <br />
                                <p style={{ marginBottom: '5px', marginTop: '5px' }}><Text tid='main_slider_4_h52' /></p><br />
                            </div>
                        </div>
                        <div className="slider-element-img"
                            style={{
                                background: 'url(https://raw.githubusercontent.com/doomer2000/speed5team-site/main/docs/assets/crm_slider.jpg)'
                                , backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'
                            }}></div>
                    </div>
                    <div className="slider-element" style={{ display: 'flex' }}>
                        <div className="slider-element-info">
                            <div>
                                <h2><Text tid='main_slider_5_h2' /></h2>
                                <p style={{ marginBottom: '10px' }}><Text tid='main_slider_5_h51' /></p>
                                <br />
                                <p style={{ marginBottom: '10px' }}><Text tid='main_slider_5_h52' /></p><br />
                            </div>
                        </div>
                        <div className="slider-element-img"
                            style={{
                                background: 'url(https://miken.net/wp-content/uploads/2018/02/tech-support-stock-image.jpg)'
                                , backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'
                            }}></div>
                    </div>
                    <div className="slider-element" style={{ display: 'flex' }}>
                        <div className="slider-element-info">
                            <div>
                                <h2><Text tid='main_slider_6_h2' /></h2>
                                <p style={{ marginBottom: '10px' }}><Text tid='main_slider_6_h51' /></p><br />
                                <p style={{ marginBottom: '10px' }}><Text tid='main_slider_6_h52' /></p><br />

                            </div>
                        </div>
                        <div className="slider-element-img" style={{
                            background: 'url(../../img/consulting_img.png)',
                            backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'
                        }}></div>
                    </div>
                </Slider>
            </div>
            <div className="box-info-flex">
                <div className="header-info-item wrapper">
                    <h3><Text tid='main_box_info_h3' /></h3>
                    <div className="crm-systems-list" style={{ justifyContent: 'flex-end' }}>
                        <div className="system-logo" style={{
                            background: 'center / contain no-repeat url(https://raw.githubusercontent.com/doomer2000/speed5team-site/main/docs/assets/elma_logo.jpg)'
                        }}></div>
                        <div className="system-logo" style={{
                            height: '450px', width: '800px',
                            background: `center / contain no-repeat url(../../img/BPMsoft_color.png)`
                        }}></div>
                        <div className="system-logo"
                            style={{
                                background: 'center / contain no-repeat url(https://raw.githubusercontent.com/doomer2000/speed5team-site/main/docs/assets/zoho_logo.gif)'
                            }}></div>
                        <div className="system-logo"
                            style={{
                                background: 'center / contain no-repeat url(https://raw.githubusercontent.com/doomer2000/speed5team-site/main/docs/assets/1C_logo.svg)'
                            }}></div>
                        <div className="system-logo"
                            style={{
                                background: 'center / contain no-repeat url(https://raw.githubusercontent.com/doomer2000/speed5team-site/main/docs/assets/Bitrix24-logo.svg)'
                                , width: '300px'
                            }}></div>
                    </div>
                </div>
            </div>
            <div className="work-steps-box" style={{ paddingBottom: '50px' }} id="work-steps">
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} className="wrapper">
                    <h3 className="work-steps-header"><Text tid='main_work_steps_h3' /></h3>
                    <div className="carousel">
                        <div className="slides">
                            <img src="../../img/analis.png" alt="Аналитика" />
                            <img src="../../img/code.png" alt="Разработка" />
                            <img src="../../img/testing.png" alt="Тестирование" />
                            <img src="../../img/release.png" alt="Релиз" />
                        </div>
                        <div className="overlays">
                            <div className="bar" style={{ background: '#252635' }}>
                                <p><Text tid='main_work_steps_p1' /></p>
                            </div>
                            <div className="bar" style={{ background: '#27273a' }}>
                                <p><Text tid='main_work_steps_p2' /></p>
                            </div>
                            <div className="bar" style={{ background: '#2a2a3f' }}>
                                <p><Text tid='main_work_steps_p3' /></p>
                            </div>
                            <div className="bar" style={{ background: '#27273a' }}>
                                <p><Text tid='main_work_steps_p4' /></p>
                            </div>
                        </div>
                        <ul className="nav-links">
                            <li className="nav-link"><Text tid='main_work_steps_li1' /></li>
                            <li className="nav-link"><Text tid='main_work_steps_li2' /></li>
                            <li className="nav-link"><Text tid='main_work_steps_li3' /></li>
                            <li className="nav-link"><Text tid='main_work_steps_li4' /></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="box-info">
                <div className="header-info-item wrapper">
                    <h3><Text tid='main_projects_h3' /></h3>
                    <div className="projects-list">
                        <div className="project-one fade-left reveal">
                            <img src="../../img/bank-icon.svg" />
                            <div className="projects-list-item">
                                <p className="projects-list-header"><Text tid='main_projects_pr1_header' /></p>
                                <p>
                                    <Text tid='main_projects_pr1_p' />
                                </p>
                            </div>
                        </div>
                        <div className="project-two fade-right reveal">
                            <img src="../../img/factory-icon.svg" />
                            <div className="projects-list-item">
                                <p className="projects-list-header"><Text tid='main_projects_pr2_header' /></p>
                                <p>
                                    <Text tid='main_projects_pr2_p' />
                                </p>
                            </div>
                        </div>
                        <div className="project-two fade-left reveal">
                            <img src="../../img/hospital-icon.svg" />
                            <div className="projects-list-item">
                                <p className="projects-list-header"><Text tid='main_projects_pr3_header' /></p>
                                <p>
                                    <Text tid='main_projects_pr3_p' />
                                </p>
                            </div>
                        </div>
                        <div className="project-one fade-right reveal">
                            <img src="../../img/shop-icon.svg" />
                            <div className="projects-list-item">
                                <p className="projects-list-header"><Text tid='main_projects_pr4_header' /></p>
                                <p>
                                    <Text tid='main_projects_pr4_p' />
                                </p>
                            </div>
                        </div>
                        <div className="project-one fade-left reveal">
                            <img src="../../img/auction-icon.svg" />
                            <div className="projects-list-item">
                                <p className="projects-list-header"><Text tid='main_projects_pr5_header' /></p>
                                <p>
                                    <Text tid='main_projects_pr5_p' />
                                </p>
                            </div>
                        </div>
                        <div className="project-two fade-right reveal">
                            <img src="../../img/computer-icon.svg" />
                            <div className="projects-list-item">
                                <p className="projects-list-header"><Text tid='main_projects_pr6_header' /></p>
                                <p>
                                    <Text tid='main_projects_pr6_p' />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};
export default Main;