﻿import * as React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, FreeMode, Thumbs } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import 'swiper/swiper-bundle.css';
import { useState } from "react";

export const Slider = ({ slides }: any) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [fullWindow, setFullWindow] = useState(false);
    
    return (
        <div className={`app-img-slider ${fullWindow ? 'full-window' : ''}`}>
            {fullWindow && < img src='/img/x.svg' className='full-window_img' onClick={() => setFullWindow(false)} />}
            <Swiper
                style={{
                    '--swiper-navigation-color': '#ababab',
                    '--swiper-pagination-color': '#1049A9',
                }}
                loop={true}
                spaceBetween={10}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper2"
            >
                {slides.map((slideContent: any, index: any) => (
                    <SwiperSlide key={slideContent.id}>
                        <img src={slideContent.path} onClick={() => setFullWindow(true)} />
                    </SwiperSlide>
                ))}
            </Swiper>
            <Swiper
                onSwiper={setThumbsSwiper}
                loop={true}
                spaceBetween={10}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper"
            >
                {slides.map((slideContent: any, index: any) => (
                    <SwiperSlide key={slideContent.id}>
                        <img src={slideContent.path} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )

}