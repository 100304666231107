﻿import * as React from 'react';
import { Link } from 'react-router-dom';
import { Text } from './Language'

const Application = (props: any) => {

    return (
        <React.Fragment>
            <div className='app-card'>
                <div>
                    <p className='app-name'>{props.language == "ru" ? props.data.name : props.data.nameEn}</p>
                    <div className='app-dev-img'>
                        <p className='app-developer'>{props.language == "ru" ? props.data.developer.name : props.data.developer.nameEn}</p>
                        <img src={props.data.developer.logo} />
                    </div>
                    <p className='app-description'>{props.language == "ru" ? props.data.shortDescription : props.data.shortDescriptionEn}</p>
                    {props.data.images.length != 0 ? <div className='app-card_img'><img src={props.data.images[1].path} /></div> : ""}
                </div>
                <div>
                    <p className='app-installs'><Text tid='app_card_installs' />: {props.data.countOfInstall}</p>
                    {props.data.onlyRu && <p className='app-installs'><Text tid='applacation_shop_show_ru_warn' /></p>}
                    <Link to={'/shop/' + props.data.id} className='app-more'><Text tid='app_card_more' /></Link>
                </div>          
            </div>
        </React.Fragment>
    );
}

export default Application