﻿import * as React from 'react';
import useScript from '../hooks/useScript';
import { Text } from './../Language'
import { Helmet } from 'react-helmet'

const Contact = () => {

    useScript('https://api-maps.yandex.ru/services/constructor/1.0/js/?um=constructor%3A4dff2897dbc1765ac0dd7c4a1eb061f02fd40d6926ea61beddd3695cfa30a38e&amp;width=100%25&amp;height=100%25&amp;lang=ru_RU&amp;scroll=true');

    return (
        <React.Fragment>
            <Helmet>
                <title>{Text({ tid: 'contacts_title' })}</title>
                <meta name="description" content={Text({ tid: 'contacts_description' })} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={Text({ tid: 'contacts_title' })} />
                <meta property="og:description" content={Text({ tid: 'contacts_description' })} />
            </Helmet>
            <div id="container_contant" style={{height: '66vh', marginTop: '100px', minHeight: '670px', display: 'flex', alignItems: 'center'}}>
                <div className="about-us-box">
                    <div className="about-us-text">
                        <h3><Text tid='contacts_h3' /></h3>
                        <div className="contacts-div">
                            <div className="contact-item">
                                <img src="../../img/phone.svg" />
                                <h5><a href="tel:+79002848612">+79002848612</a></h5>
                            </div>
                        </div>
                        <div className="contacts-div">
                            <div className="contact-item">
                                <img src="../../img/email.svg" />
                                <h5><a href="mailto:request@sprint-m.tech">request@sprint-m.tech</a></h5>
                            </div>
                        </div>
                        <div className="contacts-div">
                            <div className="contact-item">
                                <img src="../../img/map.svg" />
                                <h5><Text tid='contacts_address' /></h5>
                            </div>
                        </div>
                        <div className="contacts-div">
                            <div className="contact-item">
                                <h5 className="etc_h5"><Text tid='contacts_ooo' /></h5>
                            </div>
                        </div>
                        <div className="contacts-div">
                            <div className="contact-item">
                                <h5 className="etc_h5"><Text tid='contacts_inn' /></h5>
                            </div>
                        </div>
                    </div>
                    <div className="contacts-us-img add-script">

                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Contact