﻿import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import Dropdown from 'rsuite/Dropdown';
import AppCard from '../AppCard';
import { LanguageContext, Text } from './../Language'
import { Helmet } from 'react-helmet'

const ApplicationsShop = () => {
    const allCategory = {
        id: 0,
        name: 'Все',
        nameEn: 'All'
    }

    const sortList = [
        {
            id: 0,
            name: 'Топ установок',
            nameEn: 'Top installations'
        },
        {
            id: 1,
            name: 'Топ просмотров',
            nameEn: 'Top views'
        },
        {
            id: 2,
            name: 'Новые',
            nameEn: 'New'
        }
    ]

    const [categories, setCategories] = useState<any>([]);
    const [apps, setApps] = useState([]);
    const [allApps, setAllApps] = useState([]);
    const [sort, setSort] = useState(sortList[0]);
    const [category, setCategory] = useState<any>(allCategory);
    const [search, setSearch] = useState('');
    const [loaded, setLoaded] = useState(false);
    const [showOnlyRu, setShowOnlyRu] = useState(true);
    const { userLanguage } = useContext(LanguageContext);

    useEffect(() => {
        fetch('api/category', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => response.json())
        .then(data => {
            data.unshift({
                id: 0,
                name: 'Все',
                nameEn: 'All'
            });
            setCategories(data);
            fetch('api/shop', {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => response.json())
                .then(data => {
                    setApps(data);
                    setAllApps(data);
                    setLoaded(true);
                });
        })
        .catch(err => {
            console.log(err)
        });
    }, [])


    useEffect(() => {
        let newApps = JSON.parse(JSON.stringify(allApps));

        if (!showOnlyRu) {
            newApps = newApps.filter((el:any) => el.onlyRu == false);
        }

        if (category && category.id && category.id != categories[0].id) {
            newApps = newApps.filter((el: any) => {
                let element = el.categories.filter((item: any) => item.id == category.id);
                if (element.length != 0) {
                    return true;
                }
                return false;
            })
        }

        if (search != '') {
            // @ts-ignore
            newApps = newApps.filter((el) => el.name.toLowerCase().includes(search.toLowerCase()) || el.shortDescription.toLowerCase().includes(search.toLowerCase()))
        }

        if (sort.id === 0) {
            newApps.sort((a: any, b: any) => b.countOfInstall - a.countOfInstall)
            console.log(newApps)
        } else if (sort.id === 1) {
            newApps.sort((a: any, b: any) => b.countOfViews - a.countOfViews)
        } else {
            newApps.sort((a: any, b: any) => {
                const dateA = new Date(a.lastUpdate);
                const dateB = new Date(b.lastUpdate);
                if (dateA < dateB) {
                    return 1;
                }
                if (dateA > dateB) {
                    return -1;
                }

                return 0;
                }
            )
        }

        setApps(newApps);
    }, [sort, category, search, loaded, showOnlyRu])

    return (
        <React.Fragment>
            <Helmet>
                <title>{Text({ tid: 'applacation_shop_title' })}</title>
                <meta name="description" content={Text({ tid: 'applacation_shop_description' })} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={Text({ tid: 'applacation_shop_title' })} />
                <meta property="og:description" content={Text({ tid: 'applacation_shop_description' })} />
            </Helmet>
            <div className='app-shop'>
                <h2><Text tid='applacation_shop_h2' /></h2>
                <h4><Text tid='applacation_shop_h4' /></h4>
                <div className='search-input'>
                    <input placeholder={Text({ tid: 'applacation_shop_search' })} type='text' onChange={event => setSearch(event.target.value)} />
                    <Dropdown title={userLanguage == "ru" ? sort.name : sort.nameEn} onSelect={(eventKey: any) => setSort(sortList[eventKey])}>
                        <Dropdown.Item eventKey={0}>
                            <Text tid='applacation_shop_top_install' />
                        </Dropdown.Item>
                        <Dropdown.Item eventKey={1}>
                            <Text tid='applacation_shop_top_views' />
                        </Dropdown.Item>
                        <Dropdown.Item eventKey={2}>
                            <Text tid='applacation_shop_new' />
                        </Dropdown.Item>
                    </Dropdown>
                    <div className="showOnlyRu">
                        <input type='checkbox' id="showOnlyRu" name="showOnlyRu" checked={showOnlyRu} onClick={event => setShowOnlyRu(!showOnlyRu)}></input>
                        <label htmlFor="showOnlyRu"><Text tid='applacation_shop_show_ru' /></label>
                    </div>
                </div>
                <div className='categories'>
                    {categories.length != 0 && categories.map((item: any) => (
                        <div className={`category ${item.id === category.id ? 'category-active' : ''}`} onClick={event => setCategory(item)}>{userLanguage == 'ru' ? item.name : item.nameEn}</div>
                    ))}
                </div>
                <div className='applications'>
                    {loaded ? (apps.length != 0 ? apps.map(item => (
                        <AppCard data={item} language={userLanguage} />
                    )) : <div className='app-not-found'><Text tid='applacation_shop_not_found' /></div>) :
                        <div className='app-not-found'>
                            <img src='../../img/loading.svg' />
                        </div>}
                </div>
            </div>
        </React.Fragment>
    );
}

export default ApplicationsShop